import React, { useState } from 'react'
import axios from 'axios'

import { useAuthContext } from "../Contexts/AuthProvider";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Alert, CircularProgress, useTheme } from '@mui/material';
import useActivityLogger from '../Hooks/useActivityLogger';

import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useEnvironmentContext } from '../Contexts/EnvironmentProvider';
import AzureLogin from '../Misc/Azure/SignInButton';

interface Props {
  onAuthenticated: () => void,
  onNavigateRegister: () => void,
  onNavigateReset: () => void,
}

const Login = ({ onAuthenticated, onNavigateRegister, onNavigateReset }: Props) => {
  const { updateCredential } = useAuthContext();

  const { siteVariant } = useEnvironmentContext();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const postActivity = useActivityLogger();
  const theme = useTheme();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFacebook = (next: () => void) => {
    setLoading(true);
    next();
  }

  const handleNavigateSchool = () => {
    window.location.assign('https://schools.thevactorsclub.com/login');
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setError(null);

    setLoading(true);
    //Use a new instance to not trigger authorization interceptors
    axios.create()
      .post('/api/users/authenticate', { username: username.trim(), password: password })
      .then(async res => {
        //        setToken(res.data.token);
        await updateCredential(res.data.user, res.data.token);

        postActivity('login', {}, res.data.user.id);

        onAuthenticated();
      })
      .catch(error => {
        console.log(error);

        setLoading(false);
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
      });
  }

  const responseFacebook = async (response: any) => {
    console.log('response facebook', response);

    if (!response.id) {
      setLoading(false);
      setError('Invalid response.');
      return;
    }
    if (!response.email) {
      setLoading(false);
      setError('Invalid user email.');
      return;
    }

    try {
      const params = {
        id: response.id,
        email: response.email,
        name: response.name ?? `Facebook User #${response.id}`,
        picture: response.picture?.data?.url,
        accessToken: response.accessToken
      };

      setLoading(true);

      await axios.create().post('/api/users/facebook/create', params);
      const res = await axios.create().post('/api/users/facebook/authenticate', { id: params.id, email: params.email, accessToken: params.accessToken });

      //      setToken(res.data.token);
      await updateCredential(res.data.user, res.data.token);

      postActivity('login', {}, res.data.user.id);

      onAuthenticated();

    } catch (error: any) {
      console.log(error);

      setLoading(false);
      if (error.response && error.response.data) {
        setError(error.response.data);
      }
    }
  }

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
      onSubmit={handleSubmit}
    >
      {loading ?
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            py: '50px'
          }}
        >
          <CircularProgress />
        </Box>
        :
        <>
          {error &&
            <Alert severity="error" sx={{ mt: '20px' }}>{error}</Alert>
          }

          <TextField
            sx={{ m: '20px', mt: '40px' }}
            variant='outlined'
            value={username}
            onChange={e => setUsername(e.target.value)}
            label='User ID or Email'
          />

          <FormControl sx={{ m: '20px', mb: '0px' }} variant="outlined">
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <Button
            sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: '12px', alignSelf: 'flex-end', textTransform: 'none', mr: '20px' }}
            onClick={onNavigateReset}>
            Forgot Password
          </Button>
          <Button type='submit' sx={{
            backgroundColor: theme.palette.button.main,
            mx: '20px', mt: '10px', mb: '20px', p: '15px', textTransform: 'none', fontSize: '20pt',
            borderRadius: '20px'
          }} variant="contained">Log in</Button>

          {true &&
            <AzureLogin sx={{ alignSelf: 'center', mb: '20px' }} />
          }
          {siteVariant === 'default' && false &&
            <FacebookLogin
              appId="626434279389803"
              fields="name,email,picture.type(large)"
              callback={responseFacebook}
              render={renderProps => {
                return (
                  <Button sx={{
                    width: 'auto',
                    mx: '20px', mb: '20px', p: '5px', backgroundColor: 'white', borderColor: 'black', textTransform: 'none', fontSize: '13pt',
                    borderRadius: '15px', color: 'black'
                  }} variant="outlined" onClick={() => handleFacebook(renderProps.onClick)} startIcon={<FacebookIcon />}>Continue with Facebook</Button>
                );
              }}
            />
          }

          {siteVariant === 'default' &&
            <Stack
              sx={{
                m: '10px', mt: '-10px'
              }}
              direction='row'
              divider={<Divider orientation='vertical' variant='middle' sx={{ borderColor: theme.palette.text.primary }} flexItem />}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                sx={{ fontWeight: 'bold', fontSize: '12px', color: theme.palette.text.primary, textTransform: 'none', margin: '10px' }}
                onClick={onNavigateRegister}>
                New Member
              </Button>

              <Button
                sx={{ fontWeight: 'bold', fontSize: '12px', color: theme.palette.text.primary, textTransform: 'none', margin: '10px' }}
                onClick={handleNavigateSchool}>
                School Member
              </Button>
            </Stack>
          }
        </>
      }
    </Box>
  );
}

export default Login;
