import { Link } from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

import UserAvatar from '../UserAvatar';
import NavMenu from "./NavMenu";
import NewNotificationIndicator from '../NewNotificationIndicator';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateFrom from "../Hooks/useNavigateFrom";
import { Button, SxProps, Theme } from "@mui/material";
import GradientLine from "./GradientLine";

const Header = () => {
  const navigate = useNavigateFrom();
  const { user, hasPrivilege } = useAuthContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const buttonStyle: SxProps<Theme> = { color: theme.palette.text.primary, textTransform: 'none', fontSize: { xs: '9pt', md: '10pt' }, whiteSpace: 'nowrap', minWidth: 'auto' };

  const tabStyle: SxProps<Theme> = { color: theme.palette.text.primary, textTransform: 'none', fontSize: { xs: '9pt', md: '12pt' }, whiteSpace: 'nowrap', minWidth: 'auto', borderRadius: isMobile ? '5px' : '10px', paddingTop: '5px', paddingBottom: '5px' };
  const selectedTabStyle: SxProps<Theme> = {
    ...tabStyle, color: 'white', fontWeight: 'bold', background: theme.palette.theme.main, pointerEvents: 'none',
    "&.MuiButtonBase-root:hover": {
      bgcolor: theme.palette.theme.main, color: 'white'
    }
  };

  /*
    useEffect(() => {
      let profileUrl = `/api/users/${user?.id}`;
  
      axios.get<User>(profileUrl)
        .then(res => {
        //  const userDetail = res.data;
  
  //        if (userDetail.classes && userDetail.classes.length > 0) {
    //        setSchool(userDetail.classes[0].school.name);
      //    }
        });
    }, []);
    */

  const handleAvatarClick = () => {
    if (!user) return;

    if (user.handle)
      navigate(`/members/${user.handle}`);

    else navigate(`/profile/${user.id}`);
  }

  const getLogoSrc = () => {
    return theme.palette.mode === 'dark' ? "/logo-white.png" : "/logo.png";
  }

  const getNavMenuItems = () => {
    let ret = [];

    if (hasPrivilege('read_free_script')) {
      ret.push({ path: '/free_scripts', label: 'Free Scripts' });
    }

    //    if (hasPrivilege('template')) {
    //      ret.push({ path: '/templates', label: 'Writing Templates' });
    //    }

    if (hasPrivilege('audio_library')) {
      ret.push({ path: '/audio', label: 'Audio Library' });
    }

    return ret;
  }

  const getTabButtons = () => {
    return (
      <Stack
        sx={{
          flex: '1 1 auto'
        }}
        spacing={1}
        direction='row'
        alignItems="center"
        justifyContent='center'
      >
        {hasPrivilege('draft') &&
          <Button onClick={() => navigate('/write')} sx={window.location.pathname === '/write' ? selectedTabStyle : tabStyle}>Drafts</Button>
        }
        <Button onClick={() => navigate('/scripts')} sx={window.location.pathname === '/scripts' ? selectedTabStyle : tabStyle}>Scripts</Button>
        <Button onClick={() => navigate('/history')} sx={window.location.pathname === '/history' ? selectedTabStyle : tabStyle}>History</Button>
        <Button onClick={() => navigate('/friends')} sx={window.location.pathname === '/friends' ? selectedTabStyle : tabStyle}>Friends</Button>
        <Stack direction='column' justifyContent='center' sx={{ alignSelf: 'stretch', position: 'relative' }}>
          <NewNotificationIndicator />
          <Button onClick={() => navigate('/notifications')} sx={window.location.pathname === '/notifications' ? selectedTabStyle : tabStyle}>Messages</Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Box sx={{
      flexShrink: '0',
      flexBasis: { 'xs': 'auto', 'md': 'auto' },
      height: { 'xs': 'auto', 'md': 'auto' },
      backgroundColor: theme.palette.background.default,
      position: 'sticky', top: '0px',
      zIndex: 2
    }}>
      <Stack
        sx={{ height: { 'xs': '50px', 'md': '80px' }, px: '8px' }}
        direction='row'
        alignItems='center'
        spacing={isMobile ? 0 : 1}
        position='relative'
      >
        <NavMenu iconElement={<MenuIcon />} mobile={isMobile} items={getNavMenuItems()} />

        <img style={isMobile ?
          { cursor: 'pointer', position: 'absolute', top: '50%', left: '50%', height: '25px', transform: 'translate(-50%, -50%)' }
          : { cursor: 'pointer', height: '40px' }
        } onClick={() => navigate('/')} src={getLogoSrc()} />

        {isMobile ? <div style={{ flex: '1' }} /> : getTabButtons()}

        {user ?
          <IconButton onClick={handleAvatarClick}>
            <UserAvatar user={user} sx={{
              width: { 'xs': '40px', 'md': '60px' },
              height: { 'xs': '40px', 'md': '60px' }
            }} />
          </IconButton>
          :
          <>
            <Button key={'login'} component={Link} to='/login' sx={buttonStyle}>Log in</Button>

            <Button key={'register'} component={Link} to='/register' sx={buttonStyle}>Sign up</Button>
          </>
        }
      </Stack>

      {isMobile &&
        <Box sx={{
          marginBottom: '5px',
          backgroundColor: theme.palette.background.default,
        }}>
          {getTabButtons()}
        </Box>}

      <GradientLine />
    </Box>
  );
}

export default Header;
